import React, { Component, useState } from "react";
import Dropzone from "react-dropzone";
import csv from "csv";
import axios from "axios";
import Spinner from "react-spinkit";

import "../assets/css/fixed_height.css";

function InfluencerUpload() {
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState([]);

    function onDrop(files) {
        setFiles(files);
    }

    function uploadData() {
        setUploading(true);
        var file = files[0];

        const reader = new FileReader();
        reader.onload = () => {
            csv.parse(reader.result, (err, data) => {
                // var userList = [];

                // console.table({
                //     'Data': data,
                // });
                // setUploading(false);

                const fields = data.shift();

                let influencers = Array();

                data.forEach((element) => {
                    let newInfluencer = {};
                    // for (let i = 0; i < fields.length; i++) {
                    //     newInfluencer[fields[i]] = element[i];
                    // }

                    newInfluencer['name'] = element[0]
                    newInfluencer['phone_num'] = element[1]
                    newInfluencer['email'] = element[2]
                    newInfluencer['birthday'] = element[3]
                    newInfluencer['locations'] = element[4]
                    newInfluencer['type_of_influencer'] = element[5]
                    newInfluencer['active_handles'] = element[6]
                    newInfluencer['instagram_handle'] = element[7]
                    newInfluencer['instagram_followers'] = element[8]
                    newInfluencer['instagram_cost_per_post'] = element[9]
                    newInfluencer['instagram_cost_per_story'] = element[10]
                    newInfluencer['instagram_cost_per_video'] = element[11]
                    newInfluencer['instagram_bulk_costing'] = element[12]
                    newInfluencer['twitter_handle'] = element[13]
                    newInfluencer['twitter_followers'] = element[14]
                    newInfluencer['twitter_cost_per_post'] = element[15]
                    newInfluencer['twitter_cost_per_story'] = element[16]
                    newInfluencer['twitter_cost_per_video'] = element[17]
                    newInfluencer['twitter_bulk_costing'] = element[18]
                    newInfluencer['youtube_handle'] = element[19]
                    newInfluencer['youtube_followers'] = element[20]
                    newInfluencer['youtube_cost_per_post'] = element[21]
                    newInfluencer['youtube_cost_per_story'] = element[22]
                    newInfluencer['youtube_cost_per_video'] = element[23]
                    newInfluencer['youtube_bulk_costing'] = element[24]
                    newInfluencer['snapchat_handle'] = element[25]
                    newInfluencer['snapchat_followers'] = element[26]
                    newInfluencer['snapchat_cost_per_post'] = element[27]
                    newInfluencer['snapchat_cost_per_story'] = element[28]
                    newInfluencer['snapchat_cost_per_video'] = element[29]
                    newInfluencer['snapchat_bulk_costing'] = element[30]
                    newInfluencer['terms_and_conditions'] = element[31]
                    newInfluencer['referrer_name'] = element[32]
                    newInfluencer['task_owner'] = element[33]
                    newInfluencer['rating'] = element[34]

                    newInfluencer["registration_type"] = "influencer";
                    influencers.push(newInfluencer);
                    newInfluencer = {};
                });

                console.log(influencers);

                // for (var i = 1; i < data.length; i++) {
                //     const business_name = data[i][0];
                //     const first_name = data[i][1];
                //     const last_name = data[i][2];
                //     const mob_number = data[i][3];
                //     const email = data[i][4];
                //     const services = data[i][5];
                //     const address = data[i][6];
                //     const serv_locations = data[i][7];
                //     const rating = data[i][8];

                influencers.forEach((influencer) => {
                    try {
                        axios
                            .post(
                                (process.env.REACT_APP_API_URL ||
                                "http://127.0.0.1:8000/") + "rest-auth/register",
                                influencer
                            )
                            .then(function (response) {
                                console.log(response);
                                setUploading(false);
                            });
                    } catch (e) {
                        console.log(e);
                    }
                });
            });
        };

        reader.readAsBinaryString(file);
    }

    return (
        <div class="container center">
            <br />
            <br />
            <br />
            <h1 className="display-3">Upload New Influencers</h1>
            <h1 className="display-4">Through CSV File</h1>
            <br />
            <br />
            <br />
            <div className="dropzone" style={{ cursor: "pointer" }}>
                <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                {files.length == 0 ? (
                                    <h2
                                        class="text-center"
                                        style={{
                                            border: "2px dashed #00A4FF",
                                            width: "50%",
                                            padding: "40px",
                                        }}
                                    >
                                        Upload or drop your{" "}
                                        <font size={"5"} color="#00A4FF">
                                            CSV
                                        </font>
                                        <br /> file here.
                                    </h2>
                                ) : (
                                    <h2
                                        class="text-center"
                                        style={{
                                            border: "2px dashed #00A4FF",
                                            width: "50%",
                                            padding: "40px",
                                        }}
                                    >
                                        {files[0].name}
                                    </h2>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>
                <br />
                <br />
                <br />

                {files.length == 0 ? (
                    <div></div>
                ) : (
                    <div
                        id="blue_button"
                        class="main_purp_button text-center"
                        onClick={uploadData}
                    >
                        <p>Upload Vendor Data</p>
                    </div>
                )}

                {uploading ? (
                    <div
                        className="text-center"
                        style={{
                            position: "absolute",
                            top: "40%",
                            left: "50%",
                            "margin-left": "-30px",
                            "margin-top": "-10px",
                        }}
                    >
                        <h1 style={{ fontSize: "18px" }}>Uploading Data...</h1>
                        <Spinner
                            className="loading text-center"
                            name="three-bounce"
                            color="#8B65AE"
                            fadeIn="none"
                        />
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}

export default InfluencerUpload;
