import React, {useState, useEffect} from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import axios from 'axios';
import { NavLink, Link } from "react-router-dom";
import Spinner from 'react-spinkit';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
import '../assets/css/SF Display.css'
import '../assets/css/SF Mono.css'
import '../assets/css/SF Pro Display.css'
import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnWhiteBG from '../components/navbaronwhitebg';
import Footer from '../components/footer';

// Import Images
import VerifiedVendor from '../assets/img/verified_vendor.png';
import GenericVendor from '../assets/img/generic_vendor.png';

const dropdown_style = { 
    chips: { 
        background: "#8B65AE"
    }, 
    searchBox: { 
        'background-color': '#F8F8F8',
        'border': '2px solid transparent',
        'font-family': 'Roboto',
        'font-weight': '500',
        'height': 'auto',
        'font-size': '16px',
        'padding': '5px',
        'padding-left': '15px',
        'text-overflow': 'scroll',
        'margin-top': '15px'
    },
    option: { // To change css for dropdown options
        // 'background-color': "#8B65AE",
        'font-family': 'Roboto',
        'font-weight': '500'
      },
}

function Vendors() {

    var locations = ['All',
    'Andaman & Nicobar',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra & Nagar Haveli',
    'Daman & Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu & Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Orissa',
    'Pondicherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Tripura',
    'Uttar Pradesh',
    'Uttaranchal',
    'West Bengal']

    const [loading, setLoading] = useState(false);
    const [menu_opened, toggleMenuOpened] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [vendorValues, setVendorValues] = useState([]);
    const [services, setServices] = useState([]);
    const [displayMessage, setDisplayMessage] = useState('Choose the services you require from the field above to find the best vendor for you.');

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    function onSelectService(selectedList, selectedItem) {
        setSelectedServices(selectedList)
    }
     
    function onRemoveService(selectedList, removedItem) {
        setSelectedServices(selectedList)
    }

    function onSelectLocation(selectedList, selectedItem) {
        setSelectedLocations(selectedList)
    }
     
    function onRemoveLocation(selectedList, removedItem) {
        setSelectedLocations(selectedList)
    }

    function submitSearch(){    
    
    if (selectedServices.length == 0){
        toast.error("No services selected!", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-success'
          });
        return
    }

    if (selectedLocations.length == 0){
        toast.error("No locations selected!", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-success'
          });
        return
    }
    
    setLoading(true)
    var services_string = ''
    for(var i=0;i<selectedServices.length;i++){
        services_string += selectedServices[i]['value'] + ','
    }

    var locations_string = ''
    for(var i=0;i<selectedLocations.length;i++){
        locations_string += selectedLocations[i] + ','
    }

        try {     
            axios.get((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + 'vendors/getvendorlistings', {
                'params':{
                    services: services_string,
                    locations: locations_string
                }
            })
            .then(function(response) {
                setLoading(false)
                setVendorValues(response.data['filtered_vendors'])
                if(response.data['filtered_vendors'].length == 0) {
                    setDisplayMessage('No results found.')
                }
            });
            } catch(e) {
              console.log(e);
            }
    }

    var renderVendors = vendorValues.map(function(vendor, i){

            var vendorServices = vendor.services.map(function(service, i){
                return(
                    <li>{service}</li>
                )
            })

            var num_full_stars = vendor.rating / 2;
            var num_half_stars = vendor.rating % 2;
            var remaining_stars = 5 - num_full_stars - num_half_stars
            var rating_html = ''
            for(var i=0;i<num_full_stars;i++){
                rating_html = rating_html + '<i class="fa fa-star"></i>'
            }
            for(var i=0;i<num_half_stars;i++){
                rating_html = rating_html + '<i class="fa fa-star-half-full"></i>'
            }
            for(var i=0;i<remaining_stars;i++){
                rating_html = rating_html + '<i class="fa fa-star-o"></i>'
            }
            // rating_html = new DOMParser().parseFromString(rating_html, "text/html");
            rating_html = ReactHtmlParser(rating_html)

            return (
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 vendor_container">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        {/* <div class="vendor_img" style={{"background-image": `url(${vendor.store_pic})`}}></div> */}
                                        <div class="vendor_img" style={{"background-image": vendor.verified ? `url('${VerifiedVendor}')` : `url('${GenericVendor}')`}}></div>
                                    </div>
                                    <div class="col">
                                    <h1 class="vendor_h1" style={{"margin-bottom": "0px"}}>{vendor.business_name}</h1>
                                        {/* <p style={{"font-family": 'Montserrat',"font-size": "15px","color": "rgba(0,0,0,0.4)","margin-bottom": "5px"}}><i class="fa fa-map-marker" style={{"color": "#f44336","margin-right": "2px"}}></i>&nbsp;1.3km away<br /></p> */}
                                        <ul class="vendor_details">
    <li>Locations: <span>{vendor.locations}</span></li>
    <li>Address: <span>{vendor.address}</span></li>
    <li>Rating: 
        {/* <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-full"></i> */}
        {rating_html}
    </li>
</ul>
                                        <ul class="vendor_services">
                                            {vendorServices}
                                        </ul><br />
                                        
                                        <NavLink to='/getstarted'>
                                        <a class="active">
                                            <div id="blue_button" class="main_purp_button" style={{"margin-top": "10px"}}>
                                                <p style={{"padding": "10px 15px"}}>Request Quote</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
              );
    });

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove('menu_opened');
        document.body.style = 'background: white;';

        try {        
            axios.get((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + 'vendors/getvendorservices')
            .then(function(response) {
                // console.log(response)
                var serv = response.data['services'];
                serv.push({'name': 'All', 'value': 'All'})
                setServices(serv)
            });
            } catch(e) {
              console.log(e);
            }
      }, []);

  return (
    <div>
        <NavbarOnWhiteBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnWhiteBG>
    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex","align-items": "center","padding-left": "30px"}}>
                <h1 class="page_headings" style={{"color": "#24263C"}}>Find your Vendor</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1" style={{"padding-left": "30px"}}>
                <div class="row">
                    <div class="col-12 offset-0">
                        <form class="sticky-top" style={{"background-color": "white"}}>
                            <div class="form-row" style={{"margin-bottom": "20px"}}>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    {/* <div class="form-group"><input class="form-control" type="text" placeholder="What service are you looking for?" /></div> */}
                                    <Multiselect
                                    onSelect={onSelectService} // Function will trigger on select event
                                    onRemove={onRemoveService} // Function will trigger on remove event
                                    options={services}
                                    style={dropdown_style}
                                    closeIcon='cancel'
                                    placeholder="Select Service"
                                    displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div>
                                <div class="col">
                                    {/* <div class="form-group"><select class="form-control dropdownselect"><option value="" selected="">Select Location</option><optgroup label="This is a group"><option value="12">This is item 1</option><option value="13">This is item 2</option><option value="14">This is item 3</option></optgroup></select></div> */}
                                    <Multiselect
                                    onSelect={onSelectLocation} // Function will trigger on select event
                                    onRemove={onRemoveLocation} // Function will trigger on remove event
                                    options={locations}
                                    isObject={false}
                                    style={dropdown_style}
                                    closeIcon='cancel'
                                    placeholder="Select Location"
                                    displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div>
                                {/* <div class="col">
                                    <Multiselect
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    options={services}
                                    style={dropdown_style}
                                    closeIcon='cancel'
                                    placeholder="Sort By"
                                    displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div> */}
                                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center">
                                    <a class="active" onClick={submitSearch}>
                                        <div id="blue_button" class="main_purp_button btn-block vendor_submit" style={{"margin-bottom": "15px", 'margin-top': '15px'}}>
                                            <p style={{"padding": "10px 15px"}}><i class="fa fa-search"></i>&nbsp;&nbsp;Search<br /></p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </form>
                        <div class="row vendors_row">
                        {
                            vendorValues.length > 0 ? renderVendors : 
                            loading ? 
                            <div className='text-center' style={{'position': 'absolute', 'top': '40%', 'left': '50%', 'margin-left': '-30px', 'margin-top': '-10px'}}>
                                <h1 style={{fontSize: '18px'}}>Loading results...</h1>
                            <Spinner
                                className="loading text-center"
                                name="three-bounce"
                                color="#8B65AE"
                                fadeIn="none"
                            />
                            </div>
                            : 
                            <h2 class='text-center' style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400',fontStyle: 'italic', display: 'block', margin: 'auto'}}>{displayMessage}</h2>
                        }    

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px","margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": menu_opened ? "5": "0"}}>
            <h1 class={"home_phone_social"+ (menu_opened ? ' menu_opened': '')}>+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <br/>
    <Footer></Footer>
    </div>
  );
}

export default Vendors;
