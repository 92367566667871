import React, { useState, useEffect } from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
import "../assets/css/fixed_height.css";
import "../assets/css/SF Display.css";
import "../assets/css/SF Mono.css";
import "../assets/css/SF Pro Display.css";
import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/QuarkMe_Blue_Button.css";

// Import Components
import NavbarOnColorBG from "../components/navbaroncolorbg";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignUp() {
    const [menu_opened, toggleMenuOpened] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [organization, setOrgnization] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const authRegister = useStoreActions((actions) => actions.authRegister);

    function handleMenuClick() {
        if (menu_opened) {
            toggleMenuOpened(false);
            document.body.classList.remove("menu_opened");
        } else {
            toggleMenuOpened(true);
            document.body.classList.add("menu_opened");
        }
    }

    const organizationHandler = (e) => {
        setOrgnization(e.target.value);
    };

    const firstNameHandler = (e) => {
        setFirstName(e.target.value);
    };

    const lastNameHandler = (e) => {
        setLastName(e.target.value);
    };

    const emailHandler = (e) => {
        setEmail(e.target.value);
    };

    const passwordHandler = (e) => {
        setPassword(e.target.value);
    };

    const handleSignup = async (e) => {
        alert('This service is not available yet!');
        // const register = {
        //     email: email,
        //     password: password,
        //     first_name: firstName,
        //     last_name: lastName,
        //     registration_type: "quarkme",
        // };
        // const result = await authRegister(register);

        // if (result) {
        //     toast.success("Registration Successful", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         className: "toast-success",
        //     });
        //     setRedirect(true);
        // } else {
        //     toast.error("Registration Failed, Please Check Credentials", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         className: "toast-success",
        //     });
        //     setPassword("");
        // }
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove("menu_opened");
        document.body.style = "background: #8B65AE;";
    });

    return (
        <div>
            {redirect ? (
                <Redirect to="/blog" />
            ) : (
                <div>
                    <NavbarOnColorBG
                        menu_opened={menu_opened}
                        handleMenuClick={handleMenuClick}
                    ></NavbarOnColorBG>
                    <div class="container" style={{ "margin-top": "20px" }}>
                        <div class="row">
                            <div
                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2"
                                style={{
                                    display: "flex",
                                    "align-items": "center",
                                    "padding-left": "30px",
                                }}
                            >
                                <h1 class="join_quarkme_heading">
                                    Join the QuarkMe Platform
                                </h1>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                                <div
                                    class="row"
                                    style={{ "margin-left": "0px" }}
                                >
                                    <div class="col-12 col-sm-10 col-md-10 col-lg-8 col-xl-8 offset-0 offset-sm-1 offset-md-1 offset-lg-2 offset-xl-2">
                                        <div class="signup_container">
                                            <h1
                                                class="text-center"
                                                style={{
                                                    "font-family":
                                                        "Roboto",
                                                    "font-size": "20px",
                                                    "font-weight": "800",
                                                    color: "#24263C",
                                                }}
                                            >
                                                Create a FREE Account
                                            </h1>
                                            <p
                                                class="text-center paragraph_text"
                                                style={{
                                                    opacity: "1",
                                                    color: "rgba(0,0,0,0.5)",
                                                    "margin-top": "20px",
                                                }}
                                            >
                                                Already have an account?&nbsp;{" "}
                                                <NavLink to="/login">
                                                    <a
                                                        class="p_link"
                                                        href="/login.html"
                                                    >
                                                        Log In &gt;&gt;
                                                    </a>{" "}
                                                </NavLink>
                                            </p>
                                            <form>
                                                <div class="form-group">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Organization Name (Optional)"
                                                        style={{
                                                            "margin-top":
                                                                "30px",
                                                        }}
                                                        onChange={
                                                            organizationHandler
                                                        }
                                                    />
                                                </div>
                                                <div class="form-row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                placeholder="First Name"
                                                                style={{
                                                                    "margin-top":
                                                                        "10px",
                                                                }}
                                                                onChange={
                                                                    firstNameHandler
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                placeholder="Last Name"
                                                                style={{
                                                                    "margin-top":
                                                                        "10px",
                                                                }}
                                                                onChange={
                                                                    lastNameHandler
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        class="form-control"
                                                        type="email"
                                                        required=""
                                                        placeholder="Email"
                                                        style={{
                                                            "margin-top":
                                                                "10px",
                                                        }}
                                                        onChange={emailHandler}
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Password"
                                                        onChange={
                                                            passwordHandler
                                                        }
                                                    />
                                                </div>
                                            </form>
                                            <p
                                                class="text-left paragraph_text"
                                                style={{
                                                    opacity: "1",
                                                    "margin-top": "30px",
                                                    color: "rgba(0,0,0,0.8)",
                                                }}
                                            >
                                                By clicking ‘Create account’ or
                                                signing up, you agree to the{" "}
                                                <a href="#">Terms of Use</a> and{" "}
                                                <a href="#">Privacy Policy</a>.
                                                You also agree to receive
                                                information and offers relevant
                                                to our services via email.&nbsp;
                                            </p>
                                            <a onClick={handleSignup}>
                                                <div
                                                    id="blue_button"
                                                    class="main_purp_button btn-block text-center"
                                                    style={{
                                                        "margin-top": "10px",
                                                    }}
                                                >
                                                    <p>Create Account</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class={
                            "container home_info_container" +
                            (menu_opened ? " menu_opened" : "")
                        }
                    >
                        <div class="row">
                            <div
                                class="col-12 col-sm-8 col-md-8 col-lg-7"
                                style={{
                                    "padding-left": "30px",
                                    "margin-left": "0px",
                                }}
                            ></div>
                            <div
                                class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column"
                                style={{ "z-index": "5" }}
                            >
                                <h1
                                    id="white_socials"
                                    class="home_phone_social"
                                >
                                    +91 - 8511180202
                                    <a
                                        href="https://www.instagram.com/quarkmeofficial/"
                                        target="_blank"
                                    >
                                        <i class="fa fa-instagram home_social_icon"></i>
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/quarkme/"
                                        target="_blank"
                                    >
                                        <i class="fa fa-linkedin home_social_icon"></i>
                                    </a>
                                    <a
                                        href="https://www.facebook.com/quarkmeofficial/"
                                        target="_blank"
                                    >
                                        <i class="fa fa-facebook home_social_icon"></i>
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g"
                                        target="_blank"
                                    >
                                        <i class="fa fa-youtube-play home_social_icon"></i>
                                    </a>
                                    <a
                                        href="https://twitter.com/QuarkMeOfficial"
                                        target="_blank"
                                    >
                                        <i class="fa fa-twitter home_social_icon"></i>
                                    </a>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            )}
        </div>
    );
}

export default SignUp;
