import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
import '../assets/css/SF Display.css'
import '../assets/css/SF Mono.css'
import '../assets/css/SF Pro Display.css'
import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Images
import AnkitaShah from '../assets/img/Ankita Shah.jpeg';
import RashmiKhatri from '../assets/img/Rashmi Khatri.jpeg';
import AnamikaJha from '../assets/img/Anamika Jha.jpeg';
import PushpendraSingh from '../assets/img/Pushpendra Singh.jpeg';
import NikitaJain from '../assets/img/Nikita Jain.jpeg';
import GeniusJain from '../assets/img/Genius Jain.jpeg';

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function Testimonials() {

    const [menu_opened, toggleMenuOpened] = useState(false);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>
    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex", "align-items": "center", "padding-left": "30px"}}>
                <h1 class="page_headings">Testimonials</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div class="row">
                    <div class="col-10 offset-1">
                        <h1 style={{"font-family": 'Roboto', "font-size": "30px", "font-weight": "800", "color": "white"}}>Hear what people who have worked us say!</h1>
                        <p style={{"font-family": 'Roboto', "font-size": "17px", color: "white", "margin-bottom": "0px"}}>Our customers love us! Read what they have to say below<br /></p>
                        <div id="services_mob" class="testimonials-clean">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item" style={{"margin-top": "50px"}}>
                                    <div class="box">
                                        <p class="description">Amazing platform. QuarkMe provides to connect with your target group.</p>
                                        <p class="description" style={{"margin-top": "20px", "font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star-half-full stars"></i>&nbsp;(9/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={AnkitaShah} />
                                        <h5 class="name">Ankita Shah</h5>
                                        <p class="title">CGM Expansion, Swiggy</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item">
                                    <div class="box">
                                        <p class="description">QuarkMe is a savior! The team always supports new and creative events.</p>
                                        <p class="description" style={{"margin-top": "20px", "font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={RashmiKhatri} />
                                        <h5 class="name">Rashmi Khatri</h5>
                                        <p class="title">Organizer, Little Chef Jodhpur</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item">
                                    <div class="box">
                                        <p class="description">Amazing experience working with you guys!</p>
                                        <p class="description" style={{"margin-top": "20px", "font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={AnamikaJha} />
                                        <h5 class="name">Anamika Jha</h5>
                                        <p class="title">Marketing Executive, Zebronics</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item">
                                    <div class="box">
                                        <p class="description">It was an amazing experience working with you. (You were) professional, creative, and friendly too.</p>
                                        <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={PushpendraSingh} />
                                        <h5 class="name"><strong>Pushpendra Singh</strong></h5>
                                        <p class="title">Marketing, Swiggy Udaipur</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item">
                                    <div class="box">
                                        <p class="description">It was nice associating with you!</p>
                                        <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={NikitaJain} />
                                        <h5 class="name"><strong>Nikita Jain</strong></h5>
                                        <p class="title">Organizer, TEDxYouth@HGS, Heritage Girls School, Udaipur</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 item">
                                    <div class="box">
                                        <p class="description">You were not just a sponsor, you played a role of helping hand in the event. You were really friendly and flexible . Payments were on time too. Looking forward to more events to do with you. Thank you!</p>
                                        <p class="description"
                                            style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                    </div>
                                    <div class="author"><img class="rounded-circle" src={GeniusJain} />
                                        <h5 class="name"><strong>Genius Jain</strong></h5>
                                        <p class="title">Organizer, Super Chef Jodhpur</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel slide" data-ride="carousel" data-interval="4000" id="servicesCarousel">
                            <div class="carousel-inner" role="listbox">
                                <div class="carousel-item active testimonials-clean">
                                    <div class="row people">
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">Amazing platform. QuarkMe provides to connect with your target group.</p>
                                                <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star-half-full stars"></i>&nbsp;(9/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={AnkitaShah} />
                                                <h5 class="name">Ankita Shah</h5>
                                                <p class="title">CGM Expansion, Swiggy</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">QuarkMe is a savior! The team always supports new and creative events.</p>
                                                <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={RashmiKhatri} />
                                                <h5 class="name">Rashmi Khatri</h5>
                                                <p class="title">Organizer, Little Chef Jodhpur</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">Amazing experience working with you guys!</p>
                                                <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={AnamikaJha} />
                                                <h5 class="name">Anamika Jha</h5>
                                                <p class="title">Marketing Executive, Zebronics</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item testimonials-clean">
                                    <div class="row people">
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">It was an amazing experience working with you. (You were) professional, creative, and friendly too.</p>
                                                <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={PushpendraSingh} />
                                                <h5 class="name"><strong>Pushpendra Singh</strong></h5>
                                                <p class="title">Marketing, Swiggy Udaipur</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">It was nice associating with you!</p>
                                                <p class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={NikitaJain} />
                                                <h5 class="name"><strong>Nikita Jain</strong></h5>
                                                <p class="title">Organizer, TEDxYouth@HGS, Heritage Girls School, Udaipur</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 item">
                                            <div class="box">
                                                <p class="description">You were not just a sponsor, you played a role of helping hand in the event. You were really friendly and flexible . Payments were on time too. Looking forward to more events to do with you. Thank you!</p>
                                                <p
                                                    class="description" style={{"margin-top": "20px","font-weight": "700"}}>Rating:&nbsp;<i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i><i class="fa fa-star stars"></i>&nbsp;(10/10)</p>
                                            </div>
                                            <div class="author"><img class="rounded-circle" src={GeniusJain} />
                                                <h5 class="name"><strong>Genius Jain</strong></h5>
                                                <p class="title">Organizer, Super Chef Jodhpur</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="carouselControls"><a class="carousel-control-prev" href="#servicesCarousel" role="button" data-slide="prev"><span class="carousel-control-prev-icon"></span><span class='carouselPrevText'>Previous</span></a><a class="carousel-control-next" href="#servicesCarousel" role="button"
                                    data-slide="next"><span class='carouselNextText'>Next</span><span class="carousel-control-next-icon"></span></a></div>
                            <ol class="carousel-indicators" style={{"z-index": "1"}}>
                                <li data-target="#servicesCarousel" data-slide-to="0" class="active"></li>
                                <li data-target="#servicesCarousel" data-slide-to="1"></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px","margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": "5"}}>
            <h1 id="white_socials" class="home_phone_social">+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Testimonials;
