import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/Roboto.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function About() {

    const [menu_opened, toggleMenuOpened] = useState(false);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>
    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex","align-items": "center","padding-left": "30px"}}>
                <h1 class="page_headings">About Us</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div class="row">
                    <div class="col-10 offset-1">
                        <h1 style={{"font-family": 'Roboto',"font-size": "30px","font-weight": "800","color": "white"}}>Who are we?</h1>
                        <p style={{"font-family": 'Roboto', "font-size": "17px","color": "white","margin-bottom": "30px"}}>Our journey began in 2016, with the idea of helping brands penetrate local markets with ease. QuarkMe is a marketing startup recognized by the Indian Government under its Startup India Scheme. We offer a variety of services including
                            offline and online activations. <br /><br />At QuarkMe, we provide "Guaranteed Results" with real-time tracking for our campaigns. All our campaigns are agile in nature, giving us the flexibility to alter the course of action for
                            the most optimal results.<br /><br />QuarkMe provides hassle-free options to marketing teams for getting promised conversions. Our campaigns are designed and executed end-to-end by our in-house teams. We also extend support to
                            student societies and help them get sponsorships for their events.<br /></p>
                    </div>
                </div>
                <div class="row" style={{"margin-top": "50px"}}>
                    <div class="col-md-6 col-lg-6 col-xl-3 text-center" style={{"padding-bottom": "40px"}}>
                        <h1 style={{"font-family": 'Roboto', "font-size": "60px","font-weight": "800","color": "#eadcac"}}>90+</h1>
                        <h1 style={{"font-family": 'Roboto', "font-size": "20px", "font-weight": "800", "color": "white", "text-transform": "uppercase", "margin-top": "-10px"}}>Campaigns</h1>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 text-center" style={{"padding-bottom": "40px"}}>
                        <h1 style={{"font-family": 'Roboto', "font-size": "60px","font-weight": "800", "color": "#eadcac"}}>15+</h1>
                        <h1 style={{"font-family": 'Roboto',"font-size": "20px","font-weight": "800","color": "white","text-transform": "uppercase","margin-top": "-10px"}}>STATES</h1>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 text-center" style={{"padding-bottom": "40px"}}>
                        <h1 style={{"font-family": 'Roboto',"font-size": "60px","font-weight": "800","color": "#eadcac"}}>600+</h1>
                        <h1 style={{"font-family": 'Roboto',"font-size": "20px","font-weight": "800","color": "white","text-transform": "uppercase","margin-top": "-10px"}}>contractual jobs created</h1>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 text-center" style={{"padding-bottom": "40px"}}>
                        <h1 style={{"font-family": 'Roboto',"font-size": "60px","font-weight": "800","color": "#eadcac"}}>15+</h1>
                        <h1 style={{"font-family": 'Roboto',"font-size": "20px","font-weight": "800","color": "white","text-transform": "uppercase","margin-top": "-10px"}}>full time jobs created</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px","margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": "5"}}>
                <h1 id="white_socials" class="home_phone_social">+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default About;
