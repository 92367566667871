import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
import '../assets/css/SF Display.css'
import '../assets/css/SF Mono.css'
import '../assets/css/SF Pro Display.css'
import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Images
import Anirudh from '../assets/img/Anirudh.jpeg';
import Yug from '../assets/img/Yug.jpeg';
import Shantanu from '../assets/img/Shantanu.jpeg';
import Parth from '../assets/img/Parth.jpeg';
import Ashwat from '../assets/img/Ashwat.jpeg';
import Dinesh from '../assets/img/Dinesh.jpeg';
import Rohit from '../assets/img/Rohit.jpg';
import Isha from '../assets/img/Isha.jpeg';
import Manas from '../assets/img/Manas.jpg';
import Madhu from '../assets/img/Madhu.jpeg';
import Vasu from '../assets/img/Vasu.jpeg';
import Harshit from '../assets/img/Harshit.jpeg';
import Akshay from '../assets/img/Akshay.jpeg';
import Lucky from '../assets/img/Lucky.jpg';
import Rishabh from '../assets/img/Rishabh.jpg';
import Nainika from '../assets/img/Nainika.jpeg';
import Rahul from '../assets/img/Rahul.jpeg';
import Shail from '../assets/img/Shail.jpg';
import Vasudha from '../assets/img/Vasudha.jpeg';

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function Team() {

    const [menu_opened, toggleMenuOpened] = useState(false);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>
    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex", "align-items": "center","padding-left": "30px"}}>
                <h1 class="page_headings">Our Team</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div id="services_mob">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Anirudh})`}}></div>
                                <h1>Anirudh Sharma</h1>
                                <p>CEO | Co Founder</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Yug})`}}></div>
                                <h1>Yug Dave</h1>
                                <p>COO | Co Founder</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Shantanu})`}}></div>
                                <h1>Shantanu Sriraj</h1>
                                <p>Manager, Operations</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Parth})`}}></div>
                                <h1>Parth Bhatt</h1>
                                <p><em>Manager, Operations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Ashwat})`}}></div>
                                <h1>Ashwat Mahendran</h1>
                                <p>Manager, Creatives</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Dinesh})`}}></div>
                                <h1><strong>Dinesh</strong>&nbsp;Sharma<br /></h1>
                                <p><em>Chief Advisor, Marketing</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Rohit})`}}></div>
                                <h1>Rohit Agrawal</h1>
                                <p>Executive, Tech</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Isha})`}}></div>
                                <h1>Isha Tiwari</h1>
                                <p>Executive, Planning and BD</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Manas})`}}></div>
                                <h1>Manas Johri</h1>
                                <p><em>Executive, Planning and BD</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Madhu})`}}></div>
                                <h1><strong>Anirudh Madhusudhan</strong><br /></h1>
                                <p>Executive, Operations</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Vasu})`}}></div>
                                <h1>Vasu Kalra</h1>
                                <p><em>Executive, Operations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Harshit})`}}></div>
                                <h1>Harshit Mudgal</h1>
                                <p><em>Executive, Operations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Akshay})`}}></div>
                                <h1>Akshay Khenwar</h1>
                                <p><em>Executive, Operations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Lucky})`}}></div>
                                <h1>Lucky Dahiya</h1>
                                <p><em>Executive, Operations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Rishabh})`}}></div>
                                <h1>Rishabh Verma</h1>
                                <p>Executive, Client Relations</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Nainika})`}}></div>
                                <h1>Nainika Chadha</h1>
                                <p><em>Executive, Client Relations</em><br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Rahul})`}}></div>
                                <h1>Rahul Goyal</h1>
                                <p><em>Executive, Social</em>&nbsp;Media<br /></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Shail})`}}></div>
                                <h1><strong>Shail Yadav</strong><br /></h1>
                                <p>Intern, Design</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <div class="team text-center">
                                <div class="team_image" style={{"background-image": `url(${Vasudha})`}}></div>
                                <h1>Vasudha Khanna</h1>
                                <p>Intern, Planning and BD</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel slide" data-ride="carousel" data-interval="4000" id="servicesCarousel">
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Anirudh})`}}></div>
                                        <h1>Anirudh Sharma</h1>
                                        <p>CEO | Co Founder</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Yug})`}}></div>
                                        <h1>Yug Dave</h1>
                                        <p>COO | Co Founder</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Shantanu})`}}></div>
                                        <h1>Shantanu Sriraj</h1>
                                        <p>Manager, Operations</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Parth})`}}></div>
                                        <h1>Parth Bhatt</h1>
                                        <p><em>Manager, Operations</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Ashwat})`}}></div>
                                        <h1><strong>Ashwat Mahendran</strong><br /></h1>
                                        <p><em>Manager, Creatives</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Dinesh})`}}></div>
                                        <h1><strong>Dinesh</strong>&nbsp;Sharma<br /></h1>
                                        <p><em>Chief Advisor, Marketing</em><br /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Rohit})`}}></div>
                                        <h1>Rohit Agrawal</h1>
                                        <p>Executive, Tech</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Isha})`}}></div>
                                        <h1>Isha Tiwari</h1>
                                        <p>Executive, Planning and BD</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Manas})`}}></div>
                                        <h1>Manas Johri</h1>
                                        <p><em>Executive, Planning and BD</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Madhu})`}}></div>
                                        <h1><strong>Anirudh Madhusudhan</strong><br /></h1>
                                        <p>Executive, Operations</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Vasu})`}}></div>
                                        <h1>Vasu Kalra</h1>
                                        <p><em>Executive, Operations</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Harshit})`}}></div>
                                        <h1>Harshit Mudgal</h1>
                                        <p><em>Executive, Operations</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Akshay})`}}></div>
                                        <h1>Akshay Khenwar</h1>
                                        <p><em>Executive, Operations</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Lucky})`}}></div>
                                        <h1>Lucky Dahiya</h1>
                                        <p><em>Executive, Operations</em><br /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Rishabh})`}}></div>
                                        <h1>Rishabh Verma</h1>
                                        <p>Executive, Client Relations</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Nainika})`}}></div>
                                        <h1>Nainika Chadha</h1>
                                        <p><em>Executive, Client Relations</em><br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Rahul})`}}></div>
                                        <h1>Rahul Goyal</h1>
                                        <p><em>Executive, Social</em>&nbsp;Media<br /></p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Shail})`}}></div>
                                        <h1><strong>Shail Yadav</strong><br /></h1>
                                        <p>Intern, Design</p>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <div class="team text-center">
                                        <div class="team_image" style={{"background-image": `url(${Vasudha})`}}></div>
                                        <h1>Vasudha Khanna</h1>
                                        <p>Intern, Planning and BD</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="carouselControls"><a class="carousel-control-prev" href="#servicesCarousel" role="button" data-slide="prev"><span class="carousel-control-prev-icon"></span><span class='carouselPrevText'>Previous</span></a><a class="carousel-control-next" href="#servicesCarousel" role="button"
                            data-slide="next"><span class='carouselNextText'>Next</span><span class="carousel-control-next-icon"></span></a></div>
                    <ol class="carousel-indicators" style={{"z-index": "1"}}>
                        <li data-target="#servicesCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#servicesCarousel" data-slide-to="1"></li>
                        <li data-target="#servicesCarousel" data-slide-to="2"></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px","margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": "5"}}>
            <h1 id="white_socials" class="home_phone_social">+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Team;
