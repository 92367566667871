import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Button, CardTitle, CardText, Row, CardDeck } from "reactstrap";

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
import "../assets/css/fixed_height.css";
import "../assets/css/partnerpage.css";
// import "../assets/css/SF Display.css";
// import "../assets/css/SF Mono.css";
// import "../assets/css/SF Pro Display.css";
// import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/QuarkMe_Blue_Button.css";

// Import Components
import NavbarOnWhiteBG from "../components/navbaronwhitebg";
import Footer from "../components/footer";

function Partners() {
    const [menu_opened, toggleMenuOpened] = useState(false);
    const [closePopup, toggleClosePopup] = useState(false);

    function handleMenuClick() {
        if (menu_opened) {
            toggleMenuOpened(false);
            document.body.classList.remove("menu_opened");
        } else {
            toggleMenuOpened(true);
            document.body.classList.add("menu_opened");
        }
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove("menu_opened");
        document.body.style = "background: white;";
    });

    return (
        <div>
            <NavbarOnWhiteBG
                menu_opened={menu_opened}
                handleMenuClick={handleMenuClick}
            ></NavbarOnWhiteBG>

            <div className="hero">
                <div className="row container-fluid">
                    <h2 className="text-left home_heading">Partner with Us!</h2>
                </div>
                <div className="row container-fluid">
                    <h1>
                        If you're an individual/agency looking for more business
                        opportunities, please visit one of the appropriate
                        sections below.
                    </h1>
                </div>
            </div>

            <div className="cards">
                <Row>
                    <CardDeck>
                        <Card body>
                            <CardTitle>
                                <h2>
                                    <strong>
                                        For Social Media Influencers
                                    </strong>
                                </h2>
                            </CardTitle>
                            <CardText>
                                Collaboration with top-brands, Content creation
                                and more...
                            </CardText>
                            <Link to="/influencermarketing">
                                <Button className="btn-block" color="primary">
                                    Join as an Influencer
                                </Button>
                            </Link>
                        </Card>
        
                        <Card body>
                            <CardTitle>
                                <h2>
                                    <strong>For Promoter Vendors</strong>
                                </h2>
                            </CardTitle>
                            <CardText>
                                Activities across the Country, Real-time
                                Tracking and more...
                            </CardText>
                            <Link to="/promotervendors">
                                <Button className="btn-block" color="primary">
                                    Join as a Promoter Vendor
                                </Button>
                            </Link>
                        </Card>
                    
                    
                        <Card body>
                            <CardTitle>
                                <h2>
                                    <strong>For Printing/MBV Vendors</strong>
                                </h2>
                            </CardTitle>
                            <CardText>
                                Activities across the Country, Real-time
                                Tracking and more...
                            </CardText>
                            <Link to="/printing_OBV_MRV">
                                <Button className="btn-block" color="primary">
                                    Join as a Printing/MBV Vendor
                                </Button>
                            </Link>
                        </Card>
                    
                        <Card body>
                            <CardTitle>
                                <h2>
                                    <strong>For Creative Freelancers</strong>
                                </h2>
                            </CardTitle>
                            <CardText>
                                Escrow payments, Networking opportunities and
                                more...
                            </CardText>
                            <Link to="/freelancer">
                                <Button className="btn-block" color="primary">
                                    Join as a Freelancer
                                </Button>
                            </Link>
                        </Card>
                    
                        <Card body>
                            <CardTitle>
                                <h2>
                                    <strong>For Marketing Agencies</strong>
                                </h2>
                            </CardTitle>
                            <CardText>
                                Cross-collaboration, Lead sharing and more...
                            </CardText>
                            <Link to="/agency">
                                <Button className="btn-block" color="primary">
                                    Join as an Agency
                                </Button>
                            </Link>
                        </Card>
                        </CardDeck>
                </Row>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Partners;
