import React, { Component , useState} from 'react';
import Dropzone from 'react-dropzone';
import csv from 'csv';
import axios from 'axios';
import Spinner from 'react-spinkit';

import '../assets/css/fixed_height.css'

function VendorsUpload() {

    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState([]);

    function onDrop(files) {
        setFiles(files)
      }

    function uploadData() {
        setUploading(true);
        var file = files[0];
    
        const reader = new FileReader();
        reader.onload = () => {
        csv.parse(reader.result, (err, data) => {
            var userList = [];
    
            for (var i = 1; i < data.length; i++) {
              const business_name = data[i][0];
              const first_name = data[i][1];
              const last_name = data[i][2];
              const mob_number = data[i][3];
              const email = data[i][4];
              const services = data[i][5];
              const address = data[i][6];
              const serv_locations = data[i][7];
              const rating = data[i][8];
                
              try {        
                axios.post((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + 'rest-auth/register', {
                        business_name: business_name,
                        first_name: first_name,
                        last_name: last_name,
                        mob_number: mob_number,
                        email: email,
                        services: services,
                        address: address,
                        serv_locations: serv_locations,
                        rating: rating,
                        registration_type: 'vendor'
                })
                .then(function(response) {
                    setUploading(false)
                });
                } catch(e) {
                  console.log(e);
                }

            };
          });
        };
    
        reader.readAsBinaryString(file);
    }

  return (
    <div class="container center">
    <br /><br /><br />
    <div className="dropzone">
    <Dropzone onDrop={onDrop}>
  {({getRootProps, getInputProps}) => (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        
        {
            files.length == 0 ? <h2 class="text-center" style={{'border': '2px dashed #00A4FF', 'width': '50%', 'padding': '40px'}}>Upload or drop your <font size={'5'} color="#00A4FF">CSV</font><br /> file here.</h2>
            :
        <h2 class="text-center" style={{'border': '2px dashed #00A4FF', 'width': '50%', 'padding': '40px'}}>{files[0].name}</h2>
        }
      </div>
      
    </section>
  )}
</Dropzone>
      <br /><br /><br />

      {
            files.length == 0 ? <div></div>
            :
        <div id="blue_button" class="main_purp_button text-center" onClick={uploadData}>
            <p>Upload Vendor Data</p>
        </div>
        }

{ uploading ? <div className='text-center' style={{'position': 'absolute', 'top': '40%', 'left': '50%', 'margin-left': '-30px', 'margin-top': '-10px'}}>
            <h1 style={{fontSize: '18px'}}>Uploading Data...</h1>
            <Spinner
                className="loading text-center"
                name="three-bounce"
                color="#8B65AE"
                fadeIn="none"
            />
            </div>
            :
            <div></div>
}
    </div>
  </div>
  );
}

export default VendorsUpload;
