import React, {useState,useEffect, useRef} from 'react';
import { NavLink, Link } from "react-router-dom";
import Spinner from 'react-spinkit';

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/SF Pro Display.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnWhiteBG from '../components/navbaronwhitebg';
import Footer from '../components/footer';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

function GetStarted() {

    const [loading, setLoading] = useState(true);
    const [menu_opened, toggleMenuOpened] = useState(false);
    const { height, width } = useWindowDimensions();
    const iFrameRef = useRef(null);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    function hideSpinner() {
        setLoading(false)
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove('menu_opened');
        document.body.style = 'background: white;';
        
      });

  return (
    <div>
        <NavbarOnWhiteBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnWhiteBG>
        {loading ? (
          <div className='text-center' style={{'position': 'absolute', 'top': '40%', 'left': '50%', 'margin-left': '-30px', 'margin-top': '-10px'}}>
            <h1>Loading...</h1>
          <Spinner
            className="loading text-center"
            name="three-bounce"
            color="#8B65AE"
            fadeIn="none"
          />
          </div>
        ) : null}
        <iframe frameborder="0" style={{'height': width > 768 ? '85vh': '155vh', "width":"99%","border":"none"}} ref={iFrameRef} onLoad={hideSpinner} src='https://docs.google.com/forms/d/e/1FAIpQLSexN5kMf1hN1GtgWMnBwPjhm5XRvqxZnxukpL_096sKY8GfAg/viewform?usp=sf_link'></iframe>
        <Footer></Footer>
    </div>
  );
}

export default GetStarted;
