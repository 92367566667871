import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from "react-html-parser";
import axios from "axios";
import Spinner from 'react-spinkit';

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
// import "../assets/css/SF Display.css";
// import "../assets/css/SF Mono.css";
// import "../assets/css/SF Pro Display.css";
// import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/blog.css";
import "../assets/css/QuarkMe_Blue_Button.css";

// Import Components
import NavbarOnWhiteBG from "../components/navbaronwhitebg";
import Footer from "../components/footer";

function BlogPost(props) {
    const [loading, setLoading] = useState(true);
    const [coverImageExists, setCoverImageExists] = useState(false);
    const [menu_opened, toggleMenuOpened] = useState(false);
    const [postData, setPostData] = useState({
        title: "Test Title",
        body: "<h2>Temp body</h2>",
    });
    const logged_in = useStoreState((state) => state.user_logged_in);

    function handleMenuClick() {
        if (menu_opened) {
            toggleMenuOpened(false);
            document.body.classList.remove("menu_opened");
        } else {
            toggleMenuOpened(true);
            document.body.classList.add("menu_opened");
        }
    }

    function hideSpinner() {
        setLoading(false)
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove("menu_opened");
        document.body.style = "background: white;";

        try {
            axios.get((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + "blog/getPostData",
                    {
                        params: {
                            post_title: props.match.params.post_title,
                        },
                    }
                )
                .then(function (response) {
                    if(response.data.post_data.cover_img_url.length > 0){
                        setCoverImageExists(true)
                    }
                    setPostData(response.data.post_data);
                    hideSpinner();
                });
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <div>
            <NavbarOnWhiteBG
                menu_opened={menu_opened}
                handleMenuClick={handleMenuClick}
            ></NavbarOnWhiteBG>
            {loading ? (
          <div className='text-center' style={{'position': 'absolute', 'top': '40%', 'left': '50%', 'margin-left': '-30px', 'margin-top': '-10px'}}>
            <h1>Loading...</h1>
          <Spinner
            className="loading text-center"
            name="three-bounce"
            color="#8B65AE"
            fadeIn="none"
          />
          </div>
            ) :
            <div>
            <div
                class="container new_post_container"
                style={{ "margin-top": "60px" }}
            >
                {coverImageExists ? 
                <div class="row">
                    <img className="post_cover_img" src={postData.cover_img_url} />
                </div>
                :
                <div></div>
                }

                <div class="row">
                    <div class="col-12 col-md-10">
                        <h1 class="post_title" >{postData.title}</h1>
                        <h2 class="post_desc">{postData.desc}</h2>
                        <br />
                        <p
                            class="text-left paragraph_text blog_date"
                            style={{ "font-size": "1rem" }}
                        >
                            by{" "}
                            <span style={{ color: "#8B65AE" }}>
                                {postData.author}
                            </span>
                            , {postData.date}
                        </p>

                        {logged_in ? (
                            <Link
                                to={{
                                    pathname: "/blog/editpost",
                                    post_id: postData.post_id,
                                }}
                            >
                                <a class="paragraph_text edit_post_link">
                                    <i class="fa fa-edit"></i> Edit Post
                                </a>
                            </Link>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div
                        class="col-12 col-md-2"
                        style={{ display: "flex", "align-items": "center" }}
                    >
                        <div class="text-center">
                            <p class="share_heading">Share this story: </p>
                            <a
                                href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}
                                target="_blank"
                            >
                                <i class="fa fa-linkedin home_social_icon"></i>
                            </a>
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                target="_blank"
                            >
                                <i class="fa fa-facebook home_social_icon"></i>
                            </a>
                            <a
                                href={`https://twitter.com/share?url=<URL>&text=${window.location.href}`}
                                target="_blank"
                            >
                                <i class="fa fa-twitter home_social_icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div class="post_body" >{ReactHtmlParser(postData.body)}</div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <Footer></Footer>
            </div>}
        </div>
    );
}

export default BlogPost;
