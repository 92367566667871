import React, {useState, useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";
import axios from 'axios';

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/Roboto.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/blog.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnWhiteBG from '../components/navbaronwhitebg';
import Footer from '../components/footer';

function Blog() {

    const [menu_opened, toggleMenuOpened] = useState(false);
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [normalPosts, setNormalPosts] = useState([]);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove('menu_opened');
        document.body.style = 'background: white;';

        try {        
            axios.get((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + 'blog/getFeed')
            .then(function(response) {
                setFeaturedPosts(response.data.featured)
                setNormalPosts(response.data.normal)
            });
            } catch(e) {
              console.log(e);
            }

      }, []);


    var renderFeatured = featuredPosts.map(function(post, i){
        return (
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <div class="featured_blog_card">
                <Link to={`/blog/post/${post.title}`}>
                <div class="featured_blog_img" style={{"background-image": `url(${post.cover_img_url})`}}></div>
                </Link>
                <Link to={`/blog/post/${post.title}`} style={{ textDecoration: 'none' }}><h1 class="text-left home_heading blog_post_title">{post.title}</h1></Link>
                <p class="text-left paragraph_text blog_post_desc">{post.desc}</p>
                <p class="text-left paragraph_text blog_date">{post.date} | <span style={{"color": "#8B65AE"}}>{post.reading_time} min read</span></p>
            </div>
        </div>
        )
    });

    var renderNormal = normalPosts.map(function(post, i){
        return (
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6" style={{'margin-bottom': '40px'}}>
            <div class="blog_card">
                <div class='row'>
                    <div class='col-5'>
                    <Link to={`/blog/post/${post.title}`}>
                        <div class="featured_blog_img" style={{"background-image": `url(${post.cover_img_url})`}}></div>
                    </Link>
                    </div>
                    <div class='col-7'>
                        <Link to={`/blog/post/${post.title}`}  style={{ textDecoration: 'none' }}><h1 class="text-left home_heading blog_post_title">{post.title}</h1></Link>
                        <p class="text-left paragraph_text blog_post_desc">{post.desc}</p>
                        <p class="text-left paragraph_text blog_date">{post.date} | <span style={{"color": "#8B65AE"}}>{post.reading_time} min read</span></p>
                    </div>
                </div>
            </div>
        </div>
        )
    });

  return (
    <div>
        <NavbarOnWhiteBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnWhiteBG>
    
    <div class='container'>
        <h1 class="text-left home_heading blog_section_heading">Featured</h1>
        <div class='row' style={{'margin-top': '20px', 'margin-bottom': '-20px'}}>
            { renderFeatured.length > 0 ? renderFeatured: <h2 class='text-center' style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400',fontStyle: 'italic', display: 'block', margin: 'auto'}}>No featured posts yet</h2>}
        </div>

        <br /><hr width='80%'/><br />

        <h1 class="text-left home_heading blog_section_heading" style={{'margin-top': '10px'}}>All Posts</h1>
        <div class='row' style={{'margin-top': '40px'}}>
            {renderNormal.length > 0 ? renderNormal : <h2 class='text-center' style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: '400',fontStyle: 'italic', display: 'block', margin: 'auto'}}>No posts yet</h2>}
        </div>
    </div>

    <br /><br /><br /><br />
    <Footer></Footer>
    </div>
  );
}

export default Blog;
