import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TermsOfService = () => {
  
    const [modal, setModal] = useState(false);
  
    const toggle = () => setModal(!modal);

  return (
    <div>
    <Button color="primary" onClick={toggle}>View Terms of Service</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Terms of Service</ModalHeader>
      <ModalBody>
                        <p>Last updated: 28/02/2020</p>
                        <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before entering into the service period, accepting the services offered by QuarkMe Online Services LLP (Registered at Dwarika Nath, WZ-512, Near Pole Number 106, A1 , Village Badiyal, Palam Nagar, New Delhi - 45) commonly known by the trade name QuarkMe ("us", "we", "our" and "the company").</p>
                        <p>Your ("The Client") access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all clients, associates and others who avail our services, directly or indirectly.</p>
                        <strong>By availing the Service(s) you agree to be bound by these Terms. If you disagree with any part of the terms then you may not be able to avail our Service(s) and become liable for legal action against the usage of any Intellectual Property that may be generated by us during the service period, including the curated campaigns report and proposed action plan.</strong>
                        <strong>Termination</strong>
                        <p>We may terminate or suspend providing our Service(s) immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
                        <p>The Client and/or their Associates shall be liable for any financial dues incurred by us during the service period prior to the point of termination, provided there is compliant financial record maintained by us bifurcating all the expenses incurred during the service period towards providing our service(s) to the client.</p>
                        <p>All provisions of the Terms which by their nature should survive the termination shall survive termination, including, without limitation, ownership provisions to the created Intellectual Property, indemnity and limitations of liability.</p>
                        <strong>Content</strong>
                        <p>This clause serves as a provision to notify the client of the ownership rights to the various collateral that may be generated by us during the service period in the event of termination. The client reserves the right to the ownership of content created by us (refer below for list of covered and non-covered collateral under this clause). All content that is listed as covered will survive termination and the client will lose ownership of all content listed as non-covered in the event of termination, with immediate effect. However in the event of expiry of the service period, the client reserves all rights to the content, both covered and non-covered in the termination clause, as stated above.</p>
                        <p>The following list of collateral are classified as Intellectual Property of the company that are covered by the clause:</p>
                        <p>The following list of collateral are classified as Intellectual Property of the company that are not covered by the clause:</p>
                        <p>The client reserves complete rights to any registered trademarks or Intellectual Property shared by the client to be used by the company during the service period, without the additional modifications, if any, done by the company.</p>
                        <strong>Changes</strong>
                        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 7 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                        <strong>Indemnity</strong>
                        <strong>Limitations of Liability</strong>
                        <strong>Contact Us</strong>
                        <p>If you have any questions about these Terms, please contact us.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TermsOfService;
