import { action, thunk } from "easy-peasy";
const axios = require("axios");

export default {
    user_logged_in: false,
    token: null,
    user_data: {},

    // Thunks
    authLogin: thunk(async (actions, credentials) => {
        const res = await axios.get(
            (process.env.REACT_APP_API_URL || "https://127.0.0.1:8000/") +
                "rest-auth/login",
            {
                params: {
                    email: credentials.email,
                    password: credentials.password,
                    login_type: credentials.login_type,
                },
            }
        );
        if (res.data.status == "success") {
            var token = res.data.token;
            actions.setLoggedIn(token);
            actions.setUserData(res.data.user_data);
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem("token", token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("email", res.data.user_data.email);
            localStorage.setItem("first_name", res.data.user_data.first_name);
            localStorage.setItem("last_name", res.data.user_data.last_name);
            return true;
        } else {
            return false;
        }
    }),

    authRegister: thunk(async (actions, credentials) => {
        const res = await axios.post(
            (process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") +
                "rest-auth/register",
            {
                email: credentials.email,
                password: credentials.password,
                first_name: credentials.first_name,
                last_name: credentials.last_name,
                registration_type: credentials.registration_type,
            }
        );

        if (res.data.status == "success") {
            var token = res.data.token;
            actions.setLoggedIn(token);
            actions.setUserData(res.data.user_data);
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem("token", token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("email", res.data.user_data.email);
            localStorage.setItem("first_name", res.data.user_data.first_name);
            localStorage.setItem("last_name", res.data.user_data.last_name);
            return true;
        } else {
            return false;
        }
    }),

    checkAuthState: thunk((actions) => {
        const token = localStorage.getItem("token");
        if (token === undefined) {
            actions.logout();
        } else {
            const expirationDate = new Date(
                localStorage.getItem("expirationDate")
            );
            if (expirationDate <= new Date()) {
                // alert('Session timed out. Please Log in again!')
                console.log();
                actions.logout();
            } else {
                actions.setLoggedIn(token);
                actions.setUserData({
                    email: localStorage.getItem("email"),
                    first_name: localStorage.getItem("first_name"),
                    last_name: localStorage.getItem("last_name"),
                });
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000))
            }
        }
    }),

    // Actions
    setLoggedIn: action((state, token) => {
        state.token = token;
        if (token) state.user_logged_in = true;
    }),

    setUserData: action((state, user_data) => {
        state.user_data = user_data;
    }),

    logout: action((state) => {
        state.user_data = {};
        state.user_logged_in = false;
        state.token = null;
        localStorage.removeItem("token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("user_data");
    }),
};
