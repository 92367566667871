import React, {useState, useEffect} from 'react';
import { NavLink, Redirect } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {useStoreState} from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import axios from 'axios';

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/SF Pro Display.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/blog.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnWhiteBG from '../components/navbaronwhitebg';
import Footer from '../components/footer';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}

function NewBlogPost() {

    const [menu_opened, toggleMenuOpened] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlContent, setHtmlContent] = useState('');
    const [postTitle, setPostTitle] = useState('');
    const [postAuthor, setPostAuthor] = useState('');
    const [postDesc, setPostDesc] = useState('');
    const [redirect, setRedirect] = useState(false)
    const { height, width } = useWindowDimensions();
    const [modalIsOpen,setIsOpen] = useState(false);
    const logged_in = useStoreState(state => state.user_logged_in);
    
    function closeModal(){
        setIsOpen(false);
    }

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setHtmlContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    function postTitleChange(e){
        setPostTitle(e.target.value)
    }

    function postAuthorChange(e){
        setPostAuthor(e.target.value)
    }

    function postDescChange(e){
        setPostDesc(e.target.value)
    }

    function openModal(){
        if(postTitle.length == 0){
            toast.error("Post Title not specified!", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-success'
              });
            return
        } 

        if(htmlContent.length == 0){
            toast.error("No content in the post!", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-success'
              });
            return
        } 

        setIsOpen(true);
    }

    function publishPostHandler(){
        if(postAuthor.length == 0){
            toast.error("Author not specified!", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-success'
              });
            return
        }

        try {        
            axios.post((process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/") + 'blog/createNewPost', {
                    title: postTitle,
                    body: htmlContent,
                    author: postAuthor,
                    desc: postDesc
            })
            .then(function(response) {
                if(response.data.status == 200){
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'toast-success'
                      });
                    setRedirect(true)
                }
                else{
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'toast-success'
                      });
                    return
                }
            });
            } catch(e) {
              console.log(e);
            }
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove('menu_opened');
        document.body.style = 'background: white;';
      });

      const customStyles = {
        content : {
        top: width > 1200 ? '30%' : '50%',
        left: '50%',
        right: 'auto',
        'border-radius': '10px', 
        bottom: 'auto',
        width: width > 1200 ? '50%' : '100%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        "box-shadow": '0px 3px 10px rgba(0,0,0,0.2)',
        'z-index': '4'
        }
    };

  return (
    <div>
        {redirect ? <Redirect to='/blog' />
        :
        <div>
        <NavbarOnWhiteBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnWhiteBG>

        <div class='container new_post_container' style={{'margin-top': '60px'}}>

            {
                !logged_in ?
                <p class="text-center paragraph_text" style={{"opacity": "1","color": "rgba(0,0,0,1)","margin-top": "20px"}}>You need to be<NavLink to='/login'><a class="p_link" href="/signup.html" style={{"color": "#8B65AE"}}> logged in </a></NavLink> in order to access this page</p>
                :
                <div>
                <a onClick={openModal}>
            <div class="main_purp_button">
                <p>Publish Post</p>
            </div>
        </a>
        
        <br /><br /><br /><br />
        <input class='post_title_input' placeholder='Post Title' onChange={postTitleChange}/>
        <br /><br /> 
        <div class='text-center'>
            {modalIsOpen ? <div></div> 
            :
            <Editor
            editorState={editorState}
            wrapperClassName='wrapper'
            editorClassName="editor"
            onEditorStateChange={onEditorStateChange}
            />
            }
        </div>
        </div>
            }
    </div>

    <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="New Blog Post"
    >

    <div class='container'>
        <br />
        <div class='row'>
            <div class="col-11 text-left">
                <h1 class='post_title' style={{'fontSize': '2rem'}}>Publish new post</h1>
            </div>
            <div class="col-1 text-right">
                <a onClick={closeModal} class='modal_close_button'><i class="fa fa-close"></i></a>
            </div>
        </div>

        <div class='row'>
        <div class="col-12">
            <form>
                <div class="form-group"><input class="form-control post_data_input" type="text" placeholder="Author Name" required onChange={postAuthorChange} /></div>
                <div class="form-group"><textarea class="form-control textarea" name="message" placeholder="Description (optional)" rows="4" onChange={postDescChange}></textarea></div>
            </form>
        </div>
        </div>
        
        <br />
        <div class='row'>
        <div class="col-12">
            <a onClick={publishPostHandler}>
                <div class="main_purp_button">
                    <p>Publish Post</p>
                </div>
            </a>
        <br /><br />
        </div>
        </div>

    </div>

    </Modal>

    <br /><br /><br /><br />
    <Footer></Footer>

            </div>
            }
        </div>
    );
}

export default NewBlogPost;
