import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
import "../assets/css/fixed_height.css";
// import "../assets/css/SF Display.css";
// import "../assets/css/SF Mono.css";
// import "../assets/css/SF Pro Display.css";
// import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/QuarkMe_Blue_Button.css";

// Import Images
import SwiggyLogo from "../assets/img/swiggy-logo.svg";
import UniformTreeLogo from "../assets/img/uniform tree.png";
import ZebronicsLogo from "../assets/img/Zebronics.png";
import NibsLogo from "../assets/img/nibs.svg";
import DunzoLogo from "../assets/img/Dunzo.png";
import HeroIllustration from "../assets/img/hero_illustration.png";

// Import Components
import NavbarOnWhiteBG from "../components/navbaronwhitebg";
import Footer from "../components/footer";
import Popup from "../components/popup"

function Homepage() {
    const [menu_opened, toggleMenuOpened] = useState(false);
    const [closePopup, toggleClosePopup] = useState(false);

    function handleMenuClick() {
        if (menu_opened) {
            toggleMenuOpened(false);
            document.body.classList.remove("menu_opened");
        } else {
            toggleMenuOpened(true);
            document.body.classList.add("menu_opened");
        }
    }

    function handleClosePopup(close) {
        toggleClosePopup(close)
    }

    useEffect(() => {
        // Update the document title using the browser API
        document.body.classList.remove("menu_opened");
        document.body.style = "background: white;";
    });

    return (
        <div>
            {closePopup?null:<Popup close={handleClosePopup}/>}
            <NavbarOnWhiteBG
                menu_opened={menu_opened}
                handleMenuClick={handleMenuClick}
            ></NavbarOnWhiteBG>
            <div
                class="container hero_main_container"
                style={{ "z-index": "-3" }}
            >
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 order-2 order-sm-2 order-md-2 order-lg-2 order-xl-1 col-xs-12">
                        <h1 class="text-left home_heading">
                            The future of Marketing Campaigns!
                        </h1>
                        <p class="text-left paragraph_text">
                            QuarkMe helps brands penetrate local markets with
                            ease, extending services including offline and
                            online activations. QuarkMe provides hassle-free
                            options to marketing teams for getting promised
                            conversions through campaigns designed and fully
                            executed by its teams.
                        </p>

                        <NavLink to="/getstarted">
                            <a>
                                <div
                                    class="main_purp_button"
                                    style={{ "margin-top": "10px" }}
                                >
                                    <p>Market with Us</p>
                                </div>
                            </a>
                        </NavLink>
                        {/* <NavLink to="/services">
                            <a href="/services.html">
                                <div
                                    class="outline_purp_button"
                                    style={{
                                        "margin-top": "10px",
                                        "margin-left": "20px",
                                    }}
                                >
                                    <p>Services</p>
                                </div>
                            </a>
                        </NavLink> */}
                    </div>
                    <div class="col-8 col-sm-6 col-md-10 col-lg-8 col-xl-6 offset-2 offset-sm-3 offset-md-1 offset-lg-1 text-right order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2 col-xs-12">
                        <img
                            class="home_image"
                            src={HeroIllustration}
                            loading="lazy"
                        />
                    </div>
                </div>
                        <div class="home_brands">
                        <p
                            class="paragraph_text"
                            style={{
                                "margin-top": "20px",
                                "margin-bottom": "10px",
                                "font-size": "13px",
                                "display": "inline-block"
                            }}
                        >
                            Brands we've worked with
                        </p>
                            <a>
                                {" "}
                                <img src={SwiggyLogo} />
                                <img src={DunzoLogo} />
                                <img src={UniformTreeLogo} />
                                <img class="home_nibs_logo" src={NibsLogo} />
                                <img src={ZebronicsLogo} />
                            </a>
                        </div>
            </div>
            {/*<div
                class={
                    "container home_info_container" +
                    (menu_opened ? " menu_opened" : "")
                }
            >
                <div class="row">
                    <div
                        class="col-12 col-sm-8 col-md-8 col-lg-7"
                        style={{ "padding-left": "30px", "margin-left": "0px" }}
                    >
                        <ul class="home_services align-items-center">
                            <li style={{ "margin-left": "0px" }}>
                                Marketing Consultation
                            </li>
                            <li>BTL/TTL/ATL Marketing</li>
                            <li>Social Media Marketing</li>
                            <li>Self-Service Marketing</li>
                        </ul>
                    </div>
                    <div
                        class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column"
                        style={{ "z-index": "5" }}
                    >
                        <h1
                            class={
                                "home_phone_social" +
                                (menu_opened ? " menu_opened" : "")
                            }
                        >
                            +91 - 8511180202
                            <a
                                href="https://www.instagram.com/quarkmeofficial/"
                                target="_blank"
                            >
                                <i class="fa fa-instagram home_social_icon"></i>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/quarkme/"
                                target="_blank"
                            >
                                <i class="fa fa-linkedin home_social_icon"></i>
                            </a>
                            <a
                                href="https://www.facebook.com/quarkmeofficial/"
                                target="_blank"
                            >
                                <i class="fa fa-facebook home_social_icon"></i>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g"
                                target="_blank"
                            >
                                <i class="fa fa-youtube-play home_social_icon"></i>
                            </a>
                            <a
                                href="https://twitter.com/QuarkMeOfficial"
                                target="_blank"
                            >
                                <i class="fa fa-twitter home_social_icon"></i>
                            </a>
                        </h1>
                    </div>
                        </div>
                        </div>*/}
            <Footer></Footer>
                    </div>
    );
}

export default Homepage;
