import React, { useEffect, Component, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { ModalContainer, ModalRoute } from 'react-router-modal';
import Homepage from "./static_site/pages/homepage";
import Services from "./static_site/pages/services";
import Testimonials from "./static_site/pages/testimonials";
import About from "./static_site/pages/about";
import Contact from "./static_site/pages/contact";
import Team from "./static_site/pages/team";
import Login from "./static_site/pages/login";
import SignUp from "./static_site/pages/signup";
import Vendors from "./static_site/pages/vendors";
import VendorsUpload from "./static_site/pages/vendors_upload";
import GetStarted from "./static_site/pages/getstarted";
import Blog from "./static_site/pages/blog";
import NewBlogPost from "./static_site/pages/new_blog_post";
import EditPost from "./static_site/pages/editpost";
import BlogPost from "./static_site/pages/blogpost";
import InfluencerMarekting from "./static_site/pages/influencer";
import PromoterVendors from "./static_site/pages/promotervendor";
import PrintingOBVMRV from "./static_site/pages/printing_OBV_MRV";
import Freelancer from "./static_site/pages/freelancers";
import InfluencerUpload from "./static_site/pages/influencer_upload";
import InfluencerListing from "./static_site/pages/influencerlListing";
import Partners from "./static_site/pages/partners";
import Agency from "./static_site/pages/agency";
import TermsOfService from "./static_site/pages/termsOfService";
import PrivacyPolicy from "./static_site/pages/privacyPolicy";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";

toast.configure();

function usePageViews() {
    let location = useLocation();
    React.useEffect(() => {
        // Google Analytics Configuration
        ReactGA.initialize("UA-101378648-3");
        ReactGA.pageview(location.pathname + location.search);
        console.log(location.pathname);
    }, [location]);
}

function App() {
    usePageViews();
    useEffect(() => {
        // <!--Start of Tawk.to Script-->
        var Tawk_API = "5b51da5cdf040c3e9e0bcbf6" || {},
            Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.style.position = "absolute";
            s1.style.top = "0";
            s1.async = true;
            s1.src = "https://embed.tawk.to/5b51da5cdf040c3e9e0bcbf6/default";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
        // <!--End of Tawk.to Script-->
    }, []);

    return (
        <Switch>
            <div>
                <Route exact path="/" component={Homepage} />

                <Route exact path="/getstarted" component={GetStarted} />

                <Route exact path="/services" component={Services} />

                <Route exact path="/testimonials" component={Testimonials} />

                <Route exact path="/about" component={About} />

                <Route exact path="/contact" component={Contact} />

                <Route exact path="/team" component={Team} />

                <Route exact path="/login" component={Login} />

                <Route exact path="/signup" component={SignUp} />

                <Route exact path="/vendors" component={Vendors} />

                <Route exact path="/partners" component={Partners} />

                <Route exact path="/blog" component={Blog} />

                <Route
                    exact
                    path="/blog/post/:post_title"
                    component={BlogPost}
                />

                <Route exact path="/blog/newpost" component={NewBlogPost} />

                <Route exact path="/blog/editpost" component={EditPost} />

                <Route exact path="/uploadvendors" component={VendorsUpload} />
                <Route
                    exact
                    path="/influencermarketing"
                    component={InfluencerMarekting}
                />
                <Route
                    exact
                    path="/promotervendors"
                    component={PromoterVendors}
                />
                <Route
                    exact
                    path="/printing_OBV_MRV"
                    component={PrintingOBVMRV}
                />
                <Route exact path="/freelancer" component={Freelancer} />
                <Route exact path="/agency" component={Agency} />
                <Route
                    exact
                    path="/uploadinfluencers"
                    component={InfluencerUpload}
                />
                <Route
                    exact
                    path="/influencer-listing"
                    component={InfluencerListing}
                />

                <Route exact path="/terms" component={TermsOfService} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                </div>
        </Switch>
    );
}

export default App;
