import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
import "../assets/css/SF Display.css";
import "../assets/css/SF Mono.css";
import "../assets/css/SF Pro Display.css";
import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/QuarkMe_Blue_Button.css";

function NavbarOnColorBG(props) {
    const logged_in = useStoreState((state) => state.user_logged_in);
    const logout = useStoreActions((actions) => actions.logout);
    const [dropdown, setDropdown] = useState(false);

    function logoutHandler() {
        logout();
        toast.success("Logged out", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-success",
        });
    }
    function showDropdown() {
        setDropdown(true);
    }
    function hideDropDown() {
        setTimeout(() => setDropdown(false), 1500);
    }

    return (
        <div>
            <div class="container" style={{ "z-index": "5" }}>
                <div
                    class={
                        "menu_items" + (props.menu_opened ? " menu_opened" : "")
                    }
                >
                    <ul>
                        <li>
                            <NavLink to="/">
                                <a href="/index.html">Home</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/services">
                                <a href="/services.html">Services</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/getstarted">
                                <a href="/index.html">Campaign Query</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/partners">
                                <a href="/index.html">Be a Partner</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/testimonials">
                                <a href="/clients.html">Testimonials</a>
                            </NavLink>
                        </li>
                        <li>
                            <a href="http://quarkme.themediaant.com">
                                Ad Buying (ATL)
                            </a>
                        </li>
                        <li>
                            <NavLink to="/influencer-listing">
                                <a href="/index.html">Choose Influencers</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/vendors">
                                <a href="/index.html">Vendor Listings</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/blog">
                                <a href="/index.html">Blog</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/team">
                                <a href="/team.html">Our Team</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">
                                <a href="/aboutus.html">About</a>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">
                                <a href="/contactus.html">Contact</a>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div
                    class={
                        "menu_graphic" +
                        (props.menu_opened ? " menu_opened" : "")
                    }
                ></div>
                <div
                    class={
                        "menu_overlay" +
                        (props.menu_opened ? " menu_opened" : "")
                    }
                ></div>
                <nav
                    class="navbar navbar-light navbar-expand-md home_navbar"
                    style={{ "z-index": "2" }}
                >
                    <div class="container-fluid">
                        <NavLink to="/">
                            <div
                                class={
                                    props.menu_opened
                                        ? "logo menu_opened"
                                        : "logo"
                                }
                            ></div>
                        </NavLink>

                        {props.menu_opened ? (
                            <ul class="nav navbar-nav ml-auto align-items-center">
                                <li class="nav-item" role="presentation">
                                    <a
                                        class={
                                            "nav-link text-uppercase menu" +
                                            (props.menu_opened
                                                ? " menu_opened"
                                                : "")
                                        }
                                        onClick={props.handleMenuClick}
                                    >
                                        MENU&nbsp; &nbsp;&nbsp;
                                        <div
                                            class={
                                                "menu_icon" +
                                                (props.menu_opened
                                                    ? " menu_opened"
                                                    : "")
                                            }
                                        ></div>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    {!logged_in ? (
                                        <NavLink to="/signup">
                                            <a
                                                class="nav-link active"
                                                href="/signup.html"
                                            >
                                                <div
                                                    class={
                                                        "main_purp_button homepage_signin" +
                                                        (props.menu_opened
                                                            ? " menu_opened"
                                                            : "")
                                                    }
                                                >
                                                    <p>Sign in</p>
                                                </div>
                                            </a>
                                        </NavLink>
                                    ) : (
                                        <a
                                            class="nav-link active"
                                            onClick={logoutHandler}
                                        >
                                            <div
                                                class={
                                                    "main_purp_button homepage_signin" +
                                                    (props.menu_opened
                                                        ? " menu_opened"
                                                        : "")
                                                }
                                            >
                                                <p>Log out</p>
                                            </div>
                                        </a>
                                    )}
                                </li>
                            </ul>
                        ) : (
                            <ul class="nav navbar-nav ml-auto align-items-center">
                                <li class="nav-item" role="presentation">
                                    <NavLink to="/services">
                                        <a
                                            class={
                                                "nav-link text-uppercase menu" +
                                                (props.menu_opened
                                                    ? " menu_opened"
                                                    : "")
                                            }
                                            id="hidden-items"
                                            href="/services.html"
                                        >
                                            SERVICES
                                        </a>
                                    </NavLink>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <NavLink to="/getstarted">
                                        <a
                                            class={
                                                "nav-link text-uppercase menu" +
                                                (props.menu_opened
                                                    ? " menu_opened"
                                                    : "")
                                            }
                                            id="hidden-items"
                                            href="/index.html"
                                        >
                                            Campaign Query
                                        </a>
                                    </NavLink>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <NavLink to="/influencermarketing">
                                        <a
                                            class={
                                                "nav-link text-uppercase menu" +
                                                (props.menu_opened
                                                    ? " menu_opened"
                                                    : "")
                                            }
                                            id="hidden-items"
                                            href="/index.html"
                                        >
                                            Influencer Registration
                                        </a>
                                    </NavLink>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a
                                        class={
                                            "nav-link text-uppercase menu" +
                                            (props.menu_opened
                                                ? " menu_opened"
                                                : "")
                                        }
                                        onClick={props.handleMenuClick}
                                    >
                                        &nbsp; &nbsp;&nbsp;
                                        <div
                                            class={
                                                "menu_icon" +
                                                (props.menu_opened
                                                    ? " menu_opened"
                                                    : "")
                                            }
                                        ></div>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    {!logged_in ? (
                                        <NavLink to="/signup">
                                            <a
                                                class="nav-link active"
                                                href="/signup.html"
                                            >
                                                <div
                                                    class={
                                                        "main_purp_button homepage_signin" +
                                                        (props.menu_opened
                                                            ? " menu_opened"
                                                            : "")
                                                    }
                                                >
                                                    <p>Sign in</p>
                                                </div>
                                            </a>
                                        </NavLink>
                                    ) : (
                                        <a
                                            class="nav-link active"
                                            onClick={logoutHandler}
                                        >
                                            <div
                                                class={
                                                    "main_purp_button homepage_signin" +
                                                    (props.menu_opened
                                                        ? " menu_opened"
                                                        : "")
                                                }
                                            >
                                                <p>Log out</p>
                                            </div>
                                        </a>
                                    )}
                                </li>
                            </ul>
                        )}

                        {/* </ul> */}
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default NavbarOnColorBG;
