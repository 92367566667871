import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";
import * as emailjs from 'emailjs-com';
// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/SF Pro Display.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function Contact() {
    const [mailID,setMailID]=useState("");
    const [name,setName]=useState("");
    /*const [subject,setSubject]=useState("");*/
    const [body,setBody]=useState("")

    const handleSubmit= (e) => {
        e.preventDefault()       
        let templateParams = {
          from_name: name,
          from_email:mailID,
          to_name: 'QuarkMe',
          message_html: body,
         }   
           emailjs.send(
          'zoho',
          'template_83SaLQYe',
           templateParams,
          'user_mUozc1gQn8uD08xuLUoMe'
         )
         {/*console.log(mailID);
         console.log(name);
         console.log(body)*/}
           }

    const [menu_opened, toggleMenuOpened] = useState(false);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>

    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex","padding-left": "30px"}}>
                <h1 class="page_headings">Contact Us</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{"padding-left": "30px","margin-bottom": "50px"}}>
                        <h1 style={{"font-family": 'Roboto',"font-size": "30px","font-weight": "800","color": "white"}}>Greetings from QuarkMe!</h1>
                        <p style={{"font-family": 'Roboto',"font-size": "17px","color": "white","margin-bottom": "30px"}}>QuarkMe Team would be more than happy to serve you! Simply submit your queries through this form.<br /></p>
                        <form method="post">
                            <div class="form-group"><input class="form-control" type="text" name="name" placeholder="Name" onInput={(e)=>setName(e.target.value)} /></div>
                            <div class="form-group"><input class="form-control" type="email" name="email" placeholder="Email" onInput={(e)=>setMailID(e.target.value)} /></div>
                            {/*<div class="form-group"><input class="form-control" type="text" placeholder="Subject"  onInput={(e)=>setSubject(e.target.value)}/></div>*/}
                            <div class="form-group"><textarea class="form-control textarea" name="message" placeholder="Message" rows="6" onInput={(e)=>setBody(e.target.value)}></textarea></div>
                            <a href="#">
                                <div id="blue_button" class="main_purp_button btn-block text-center" onClick={handleSubmit}>
                                    <p>Submit</p>
                                </div>
                            </a>
                        </form>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{"padding-left": "30px"}}>
                        <h1 style={{"font-family": 'Roboto',"font-size": "30px","font-weight": "800","color": "white"}}>Get in touch</h1>
                        <p style={{"font-family": 'Roboto',"font-size": "17px","color": "white","margin-bottom": "10px"}}><i class="fa fa-home" style={{"font-size": "20px","margin-right": "10px"}}></i>QuarkMe, Atal Incubation Centre, SNU, Dadri, G.Noida, UP 201314<br /></p>
                        <p style={{"font-family": 'Roboto',"font-size": "17px","color": "white","margin-bottom": "10px"}}><i class="fa fa-phone" style={{"font-size": "20px","margin-right": "10px"}}></i>+91 8130883516<br /></p>
                        <p style={{"font-family": 'Roboto',"font-size": "17px","color": "white","margin-bottom": "10px"}}><i class="fa fa-envelope" style={{"font-size": "20px","margin-right": "10px"}}></i>contact@quarkme.com<br /></p>
                        <div style={{"margin-bottom":"30px"}}>
                        <a style={{"margin-right":"0px"}}href="https://www.instagram.com/quarkmeofficial/" target="_blank">
                            <i class="fa fa-instagram home_social_icon"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/quarkme/" target="_blank">
                            <i class="fa fa-linkedin home_social_icon"></i>
                        </a>
                        <a href="https://www.facebook.com/quarkmeofficial/" target="_blank">
                            <i class="fa fa-facebook home_social_icon"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank">
                            <i class="fa fa-youtube-play home_social_icon"></i>
                        </a>
                        <a href="https://twitter.com/QuarkMeOfficial" target="_blank">
                            <i class="fa fa-twitter home_social_icon"></i>
                        </a>
                        </div>
                        <iframe allowfullscreen="" frameborder="0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBxMnqm9iZ1Q48w5DnKMsIkruF1GH8HPgk&amp;q=QuarkMe,+B322-F,+Atal+Incubation+Centre,+SNU,+Uttar+Pradesh+201314+&amp;zoom=15"
                            width="100%" height="320"></iframe></div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Contact;
