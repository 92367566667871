import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/SF Pro Display.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function Services() {

    const [menu_opened, toggleMenuOpened] = useState(false);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>
    <div class="container" style={{"margin-top": "50px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex", "align-items": "center", "padding-left": "30px"}}>
                <h1 class="page_headings">Our Services</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div id="services_mob">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>Marketing Consultation</h1>
                                <p>From understanding of client's query to helping them formulate the problem statement, to designing a marketing campaign that serves the exact purpose through out of the box ideas, we act as the perfect partner in marketing planning.<br /></p>
                                <NavLink to='/getstarted'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Request Callback</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>BTL/TTL/ATL<br /></h1>
                                <p>Executing a BTL campaign in the fields is altogether a different level of difficulty. We take care of designing a campaign, aligning vendors, improvising, and ensuring the outcomes from a BTL/TTL campaign. The best part is that we guarantee results, and run free backup campaigns if the original one fails. ATL campaigns are no less in terms of challenges. Allow us to share the load and take responsibility of making your campaigns successful.<br /></p>
                                <NavLink to='/getstarted'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Request Callback</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>Self-Service Listings</h1>
                                <p>At QuarkMe, we provide end-to-end execution for all your marketing needs. If that's not your cup of tea, then you can always have a consultation with us first, then compare and choose from our list of verified vendors. Once you have your vendor(s), track your campaign in real-time and make payments via our portal for a secure and hassle-free experience. If at any moment you need to take off the pressure and opt in for our end-to-end services, our team is always available on standby, waiting to assist you. Manage all your campaigns via a single portal to make executing marketing activities, a truly hassle-free experience for you and your team!</p>
                                <NavLink to='/vendors'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Browse Listings</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>Social Media & Influencer Marketing</h1>
                                <p>How can you make a lasting impression of your brand when your audience is surrounded by a multitude of competitive brands? Build your online presence with our Social Media Management Services. Get in touch for a custom made social media strategy tailored specially for boosting your brands reach across social networks such as Facebook, Instagram and LinkedIn etc.</p>
                                <NavLink to='/getstarted'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Request Callback</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>Events and Sponsorship</h1>
                                <p>A successful event needs meticulous planning and execution. We offer end-to-end planning and execution of your events and conferences (you name us, we execute it for you). We also provide co-branding and sponsorship opportunities for your brand to stand out of the crowd.</p>
                                <NavLink to='/getstarted'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Request Callback</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="services">
                                <h1>Sampling & in-app marketing</h1>
                                <p>Want to leverage our partner brands' distribution networks and realtime in-app engagements?  Explore distribution and sampling opportunities with brands like Swiggy and more!.</p>
                                <NavLink to='/getstarted'>
                                <a href="#">
                                    <div id="blue_button" class="main_purp_button">
                                        <p>Request Callback</p>
                                    </div>
                                </a>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel slide" data-ride="carousel" data-interval="4000" id="servicesCarousel">
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>Marketing Consultation</h1>
                                        <p class='content_cutter'>From understanding of client's query to helping them formulate the problem statement, to designing a marketing campaign that serves the exact purpose through out of the box ideas, we act as the perfect partner in marketing planning.<br /></p>
                                        <NavLink to='/getstarted'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Request Callback</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>BTL/TTL/ATL<br /></h1>
                                        <p class='content_cutter'>Executing a BTL campaign in the fields is altogether a different level of difficulty. We take care of designing a campaign, aligning vendors, improvising, and ensuring the outcomes from a BTL/TTL campaign. The best part is that we guarantee results, and run free backup campaigns if the original one fails. ATL campaigns are no less in terms of challenges. Allow us to share the load and take responsibility of making your campaigns successful.<br /></p>
                                        <NavLink to='/getstarted'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Request Callback</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>Self-Service Listings</h1>
                                        <p class='content_cutter'>At QuarkMe, we provide end-to-end execution for all your marketing needs. If that's not your cup of tea, then you can always have a consultation with us first, then compare and choose from our list of verified vendors. Once you have your vendor(s), track your campaign in real-time and make payments via our portal for a secure and hassle-free experience. If at any moment you need to take off the pressure and opt in for our end-to-end services, our team is always available on standby, waiting to assist you. Manage all your campaigns via a single portal to make executing marketing activities, a truly hassle-free experience for you and your team!</p>
                                        <NavLink to='/vendors'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Browse Listings</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>Social Media & Influencer Marketing</h1>
                                        <p class='content_cutter'>How can you make a lasting impression of your brand when your audience is surrounded by a multitude of competitive brands? Build your online presencewith our Social Media Management Services. Get in touch for a custom made social media strategy tailored specially for boosting your brands reach across social networks such as Facebook, Instagram and LinkedIn etc.</p>
                                        <NavLink to='/getstarted'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Request Callback</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>Events and Sponsorship</h1>
                                        <p class='content_cutter'>A successful event needs meticulous planning and execution. We offer end-to-end planning and execution of your events and conferences (you name us, we execute it for you). We also provide co-branding and sponsorship opportunities for your brand to stand out of the crowd.<br /></p>
                                        <NavLink to='/getstarted'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Request Callback</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="services">
                                        <h1>Sampling & in-app marketing<br /></h1>
                                        <p class='content_cutter'>Want to leverage our partner brands' distribution networks and realtime in-app engagements?  Explore distribution and sampling opportunities with brands like Swiggy and more!<br /></p>
                                        <NavLink to='/getstarted'>
                                        <a href="#">
                                            <div id="blue_button" class="main_purp_button">
                                                <p>Request Callback</p>
                                            </div>
                                        </a>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="carouselControls"><a class="carousel-control-prev" href="#servicesCarousel" role="button" data-slide="prev"><span class="carousel-control-prev-icon"></span><span class='carouselPrevText'>Previous</span></a><a class="carousel-control-next" href="#servicesCarousel" role="button"
                            data-slide="next"><span class='carouselNextText'>Next</span><span class="carousel-control-next-icon"></span></a></div>
                    <ol class="carousel-indicators" style={{"z-index": "1"}}>
                        <li data-target="#servicesCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#servicesCarousel" data-slide-to="1"></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px", "margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": "5"}}>
            <h1 id="white_socials" class="home_phone_social">+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Services;
