import React from "react";
import { NavLink } from "react-router-dom";

import popupImage from "../assets/img/Story_wo CTA.jpg";
import "../assets/css/popup.css";

function Popup(props) {
    function handlePopupClose() {
        props.close(true);
    }
    return (
        <div class="popup-container">
            <div class="image-container">
                {/* <img src={popupImage}/> */}
                <div class="close-button" onClick={handlePopupClose}>
                    ✖
                </div>
            </div>
                {/*<NavLink to="/getstarted">
                <a>
                    <div
                        class="main_popup_button"
                        style={{ "margin-top": "15px"}}
                    >
                        <p>Claim your 5000 Credits</p>
                    </div>
                </a>
            </NavLink>*/}
        </div>
    );
}

export default Popup;
