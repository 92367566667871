import React, {useState,useEffect} from 'react';
import { NavLink, Link, Redirect } from "react-router-dom";
import {useStoreActions} from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import CSS Files
import '../assets/css/Testimonials.css'
import '../assets/css/styles.css'
import '../assets/css/fixed_height.css'
// import '../assets/css/SF Display.css'
// import '../assets/css/SF Mono.css'
// import '../assets/css/SF Pro Display.css'
// import '../assets/css/SF Text.css'
import '../assets/css/Brands.css'
import '../assets/css/checkbox.css'
import '../assets/css/fluid-gallery.css'
import '../assets/css/Footer-Dark.css'
import '../assets/css/QuarkMe_Blue_Button.css'

// Import Components
import NavbarOnColorBG from '../components/navbaroncolorbg';
import Footer from '../components/footer';

function Login() {

    const [menu_opened, toggleMenuOpened] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false)
    const authLogin = useStoreActions(actions => actions.authLogin);

    function handleMenuClick() {
        if(menu_opened){
            toggleMenuOpened(false);
            document.body.classList.remove('menu_opened');
        }
        else{
            toggleMenuOpened(true);
            document.body.classList.add('menu_opened');
        }
    }

    function emailHandler(e){
        setEmail(e.target.value)
    }

    function passwordHandler(e){
        setPassword(e.target.value)
    }

    const handleLogin = async (e) => {
        const result = await authLogin({email: email, password: password, login_type: 'quarkme'})
        
        if(result){
            toast.success("Login Successful!", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-success'
              });
                setRedirect(true)
        }
        else{
            toast.error("Invalid Credentials!", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-success'
              });
        }
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
    // Update the document title using the browser API
    document.body.classList.remove('menu_opened');
    document.body.style = 'background: #8B65AE;';
  });

  return (
    <div>
        {redirect ? <Redirect to='/blog' />
        :
        <div>
        <NavbarOnColorBG menu_opened={menu_opened} handleMenuClick={handleMenuClick}></NavbarOnColorBG>
    <div class="container" style={{"margin-top": "20px"}}>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2" style={{"display": "flex","align-items": "center","padding-left": "30px"}}>
                <h1 class="join_quarkme_heading">Join the QuarkMe Platform</h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-1">
                <div class="row" style={{"margin-left": "0px"}}>
                    <div class="col-12 col-sm-10 col-md-10 col-lg-8 col-xl-8 offset-0 offset-sm-1 offset-md-1 offset-lg-2 offset-xl-2">
                        <div class="signup_container">
                            <h1 class="text-center" style={{"font-family": 'Roboto',"font-size": "20px","font-weight": "800","color": "#24263C"}}>Log in to your account</h1>
                            <p class="text-center paragraph_text" style={{"opacity": "1","color": "rgba(0,0,0,0.5)","margin-top": "20px"}}>Don't have an account?&nbsp;&nbsp; <NavLink to='/signup'><a class="p_link" href="/signup.html">Sign up &gt;&gt;</a></NavLink></p>
                            <form>
                                <div class="form-group"><input class="form-control" type="email" required="" placeholder="Email" style={{"margin-top": "40px"}} onChange={emailHandler}/></div>
                                <div class="form-group"><input class="form-control" type="password" placeholder="Password" onChange={passwordHandler}/></div>
                                {/* <label class="check">Remember Me
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                                </label> */}
                            </form>
                            <a onClick={handleLogin}>
                                <div id="blue_button" class="main_purp_button btn-block text-center" style={{"margin-top": "20px"}}>
                                    <p>Log In</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class={"container home_info_container" + (menu_opened ? ' menu_opened': '')}>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-7" style={{"padding-left": "30px","margin-left": "0px"}}></div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-5 text-right home_social_column" style={{"z-index": "5"}}>
            <h1 id="white_socials" class="home_phone_social">+91 - 8511180202
                <a href="https://www.instagram.com/quarkmeofficial/" target="_blank"><i class="fa fa-instagram home_social_icon"></i></a>
                <a href="https://www.linkedin.com/company/quarkme/" target="_blank"><i class="fa fa-linkedin home_social_icon"></i></a>
                <a href="https://www.facebook.com/quarkmeofficial/" target="_blank"><i class="fa fa-facebook home_social_icon"></i></a>
                <a href="https://www.youtube.com/channel/UCSlDG2fV1HQWpyBw2geC65g" target="_blank"><i class="fa fa-youtube-play home_social_icon"></i></a>
                <a href="https://twitter.com/QuarkMeOfficial" target="_blank"><i class="fa fa-twitter home_social_icon"></i></a>
                </h1>
            </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
    }
    </div>
  );
}

export default Login;
