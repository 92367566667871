import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

// Import CSS Files
import "../assets/css/Testimonials.css";
import "../assets/css/styles.css";
import "../assets/css/SF Display.css";
import "../assets/css/SF Mono.css";
import "../assets/css/SF Pro Display.css";
import "../assets/css/SF Text.css";
import "../assets/css/Brands.css";
import "../assets/css/checkbox.css";
import "../assets/css/fluid-gallery.css";
import "../assets/css/Footer-Dark.css";
import "../assets/css/QuarkMe_Blue_Button.css";

import TermsOfService from "../pages/termsOfService";

function Footer() {
    return (
        <div>
            <div class="footer-dark">
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <p class="home_footer_text">
                                    ©2019 <strong>QuarkMe </strong>is recognized
                                    as a ‘Startup’ by the Department of
                                    Industrial Policy and Promotion, under the
                                    Startup India Scheme. All Rights Reserved.
                                </p>
                            </div>
                            <div class="col-12 col-sm-6 text-right">
                                <p
                                    class="home_footer_text home_footer_text_right"
                                    style={{ width: "auto" }}
                                >
                                    Website owned and managed by QuarkMe Online
                                    Services LLP, INDIA.
                                    <Link to="/terms">
                                    <button type="button" class="home_footer_links btn btn-link" data-toggle="modal" data-target="#ToS">
                                        Terms of Service
                                    </button>
                                    </Link>
                                    <Link to="/privacy">
                                    <button type="button" class="home_footer_links btn btn-link" data-toggle="modal" data-target="#PrivacyPolicy">
                                        Privacy Policy
                                    </button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Footer;
